import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import WaitingModal from "./modals/WaitingModal";

/* The Main component redirects the user to the home page if they have been authenticated with okta.
   If the user has not been authenticated with okta they will be redirected to the okta login page */
const Main = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth
        .getUser()
        .then((info) => setUserInfo(info))
        .catch((err) => console.error(err));
    }
  }, [authState, oktaAuth]); // Update if authState changes

  if (!authState) {
    return <WaitingModal isLoading />;
  }

  return (
    <div id="home" style={{ padding: "5em" }}>
      <div>
        {authState.isAuthenticated && !userInfo && <WaitingModal isLoading />}

        {authState.isAuthenticated && userInfo && (
          <div>
            <Redirect to="/home" />
          </div>
        )}
        {!authState.isAuthenticated && (
          <div>
            <Redirect to="/login" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Main;
