import React, { useState } from "react";
import { Route, useHistory, Switch } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import config from "../config";
import Home from "../components/home/Home";
import Main from "../components/Main";
import OktaLoginComponent from "../components/login/Login";
import ReviewersForm from "../components/reviewers/ReviewersForm";
import UserGuide from "../components/userGuide/UserGuide";
import CorsErrorModal from "../components/modals/CorsErrorModal";

const oktaAuth = new OktaAuth(config.okta);

const AppRouter = () => {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  const customAuthHandler = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    history.push("/login");
  };

  const onAuthResume = async () => {
    history.push("/login");
  };

  const [corsErrorModalOpen, setCorsErrorModalOpen] = useState(false);

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <CorsErrorModal {...{ corsErrorModalOpen, setCorsErrorModalOpen }} />
      <Switch>
        <Route path="/" exact component={Main} />
        <Route
          path="/login/callback"
          render={(props) => (
            <LoginCallback {...props} onAuthResume={onAuthResume} />
          )}
        />
        <Route
          path="/login"
          render={() => <OktaLoginComponent {...{ setCorsErrorModalOpen }} />}
        />
        <SecureRoute path="/home" component={Home} />
        <SecureRoute path="/reviewers" component={ReviewersForm} />
        <SecureRoute path="/user-guide" component={UserGuide} />
      </Switch>
    </Security>
  );
};

export default AppRouter;
