// private config
const location = window.location;
const url = `${location.protocol}//${location.hostname}`;

const local = {
  endpoints: {
    backend: "https://api.dev.repository-creator.genesyspsdevops-dev.com"
  },
  okta: {
    issuer: "https://dev-00014457.okta.com/oauth2/default",
    clientId: "0oa3rzpmx7NFccHg45d7",
    redirectUri: "http://localhost:3000/login/callback",
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: true,
    postLogoutRedirectUri: "http://localhost:3000/"
  },
  app: {
    basename: ""
  }
};

const development = {
  endpoints: {
    backend: "https://api.dev.repository-creator.genesyspsdevops-dev.com"
  },
  okta: {
    issuer: "https://dev-00014457.okta.com/oauth2/default",
    clientId: "0oa3rzpmx7NFccHg45d7",
    redirectUri: `${url}/login/callback`,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: true,
    postLogoutRedirectUri: `${url}/`
  },
  app: {
    basename: ""
  }
};

const test = {
  endpoints: {
    backend: "https://api.test.repository-creator.genesyspsdevops-dev.com"
  },
  okta: {
    issuer: "https://dev-00014457.okta.com/oauth2/default",
    clientId: "0oa41ztxodeR2Vqoi5d7",
    redirectUri: `${url}/login/callback`,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: true,
    postLogoutRedirectUri: `${url}/`
  },
  app: {
    basename: ""
  }
};

const production = {
  endpoints: {
    backend: "https://api.repository-creator.genesyspsdevops.com"
  },
  okta: {
    issuer: "https://genesys.okta.com/oauth2/default",
    clientId: "0oa1o2y2zx7gCs8g50h8",
    redirectUri: `${url}/login/callback`,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: true,
    postLogoutRedirectUri: `${url}/`
  },
  app: {
    basename: ""
  }
};

const env = process.env.REACT_APP_CUSTOM_ENV.trim();
console.log("env:", env);
let config;
switch (env) {
  case "production":
    config = production;
    break;
  case "development":
    config = development;
    break;
  case "test":
    config = test;
    break;
  case "local":
    config = local;
    break;
  default:
    break;
}

export default config;
