import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect } from "react";
import backendAPI from "../../services/api";
import utils from "../../services/utils";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ViewProjectReviewers from "./ViewProjectReviewers";
import ModifyProjectReviewers from "./ModifyProjectReviewers";
import SelectProjectSettings from "./SelectProjectSettings";
import WaitingModal from "../modals/WaitingModal";
import AlertModal from "../modals/AlertModal";
import { Box, Grid } from "@mui/material";
import background from "../../img/gplaypattern.png";
import { useLocation, Redirect } from "react-router-dom";

/* The ReviewersForm component will only be rendered if the user is an admin of at least one workspace and they select the reviewers navigation tab.
   If a user tried to go to this page directly they will be redirected to the main page */
const ReviewersForm = () => {
  // okta user authentication
  const { oktaAuth } = useOktaAuth();

  // reviewers form select menu values
  const [config, setConfig] = useState([]);
  const [workSpaceList, setWorkSpaceList] = useState([]);
  const [projectList, setProjectList] = useState([]);

  // reviewers form values the user selected
  const [workspace, setWorkspace] = useState("");
  const [projectKey, setProjectKey] = useState("");
  const [projectName, setProjectName] = useState("");

  // Bitbucket project info
  const [projectId, setProjectId] = useState("");
  const [projectReviewers, setProjectReviewers] = useState([]);
  const [workspaceMembers, setWorkspaceMembers] = useState([]);

  // utils
  const [projectReviewersAction, setProjectReviewersAction] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState("");
  const [error, setError] = useState("");
  const location = useLocation();
  const redirect = location.state === undefined ? true : false;

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      try {
        const accessToken = oktaAuth.getAccessToken();
        const userInfo = await oktaAuth.getUser();
        const userName = userInfo.name;
        if (isMounted) {
          setIsLoading(true);
        }
        const data = await backendAPI.getConfig(accessToken);
        if (isMounted) {
          await loadForm(data, userName);
          setIsLoading(false);
        }
      } catch (error) {
        setError(error.message);
      }
    };
    getData();
    return () => {
      isMounted = false;
    };
  }, [oktaAuth]);

  useEffect(() => {
    let isMounted = true;
    const accessToken = oktaAuth.getAccessToken();
    const sendData = async () => {
      try {
        // Display loading modal
        if (isMounted) {
          setText("Saving Project Reviewers");
          setIsLoading(true);
        }
        // API call to modify reviewers table
        const requestBody = {
          key: projectKey,
          reviewers: projectReviewers
        };
        await backendAPI.putProjectReviewers(
          workspace,
          projectId,
          requestBody,
          accessToken
        );
        // API call to update the config
        const data = await backendAPI.getConfig(accessToken);
        // After the config is set remove loading modal and display reviewers list
        if (isMounted) {
          setConfig(data);
          setIsLoading(false);
          setProjectReviewersAction("view");
        }
      } catch (error) {
        setError(error.message);
      }
    };
    // If the user clicked save then update the project reviewers form
    if (projectReviewersAction === "save") {
      sendData();
    }
    return () => {
      isMounted = false;
    };
  }, [
    projectReviewers,
    oktaAuth,
    projectKey,
    projectId,
    projectReviewersAction,
    workspace
  ]);

  // set reviewers intitial form state
  const loadForm = async (data, userName) => {
    setConfig(data);
    setWorkSpaceList(utils.getAdminWorkspaces(data, userName));
  };

  // reviewers form handlers for changing the select settings
  const handleWorkspaceChange = (event) => {
    const workspaceValue = event.target.value;
    setWorkspace(workspaceValue);
    // If user changes the workspace when project is selected, reset state
    if (!!projectName) {
      setProjectKey("");
      setProjectName("");
      setProjectId("");
      setProjectReviewers([]);
      setProjectReviewersAction("");
    }
    const projectList = utils.getProjectNames(config, workspaceValue);
    setProjectList(projectList);
    // If there are no projects associated with a Bitbucket workspace display error modal
    if (projectList.length === 0) {
      setError(
        `There are no projects in the ${workspaceValue} Bitbucket workspace; Please create projects in your Bitbucket workspace`
      );
    }
  };

  const handleProjectChange = (event) => {
    const projectName = event.target.value;
    setProjectName(projectName);
    const projectId = utils.getProjectId(config, workspace, projectName);
    setProjectId(projectId);
    const projectReviewers = utils.getProjectReviewers(
      config,
      workspace,
      projectId
    );
    setProjectReviewers(projectReviewers);
    setProjectKey(utils.getProjectKey(config, workspace, projectName));
    // Display the project reviewers
    setProjectReviewersAction("view");
    setWorkspaceMembers(
      utils.getWorkspaceMembers(config, workspace, projectReviewers)
    );
  };

  // handles button clicks for the modify reviewers form
  const handleButtonClick = (e, updatedProjectReviewersList = "") => {
    if (e.target.innerText.toLowerCase() === "cancel") {
      setProjectReviewersAction("view");
    } else if (e.target.innerText.toLowerCase() === "save") {
      setProjectReviewersAction("save");
      setProjectReviewers(updatedProjectReviewersList);
    } else {
      setProjectReviewersAction(e.target.innerText.toLowerCase());
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundImage: `url(${background})`
      }}
    >
      {/*If the user tried to go to /reviewers path directly redirect to main page */}
      {redirect && <Redirect to="/" />}
      <WaitingModal isLoading={isLoading} header={!text ? "Loading" : text} />
      <AlertModal
        isOpen={!!error}
        toggle={() => {
          setError(undefined);
        }}
        header="Error"
        body={error}
      />
      <Box sx={{ marginBottom: "auto" }}>
        <Header adminAccess />
      </Box>
      <Box
        sx={{
          bgcolor: "background.paper",
          boxShadow: 15,
          border: "2px solid rgba(35,57,93)",
          borderRadius: 3,
          maxWidth: "90vw",
          padding: "1%",
          margin: "auto"
        }}
      >
        <Grid
          container
          direction="row"
          spacing={{ xs: 4, sm: 0, md: 0, lg: 0 }}
        >
          <Grid item xs={12} sm={5} md={5} lg={5}>
            <SelectProjectSettings
              handleWorkspaceChange={handleWorkspaceChange}
              workSpaceList={workSpaceList}
              workspace={workspace}
              handleProjectChange={handleProjectChange}
              projectList={projectList}
              projectName={projectName}
            />
          </Grid>
          {projectReviewersAction === "view" && (
            <Grid item xs={12} sm={7} md={7} lg={7}>
              <ViewProjectReviewers
                projectReviewers={projectReviewers}
                handleButtonClick={handleButtonClick}
              />
            </Grid>
          )}
          {(projectReviewersAction === "modify" ||
            projectReviewersAction === "save") && (
            <Grid item xs={12} sm={7} md={7} lg={7}>
              <ModifyProjectReviewers
                projectReviewers={projectReviewers}
                workspaceMembers={workspaceMembers}
                handleButtonClick={handleButtonClick}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box component="footer" sx={{ mt: "auto" }}>
        <Footer />
      </Box>
    </Box>
  );
};

export default ReviewersForm;
