import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  TableRow,
  TableCell,
  Table,
  Paper,
  TableHead,
  TableBody,
  TableContainer
} from "@mui/material";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import background from "../../img/gplaypattern.png";
import { useLocation, Redirect } from "react-router-dom";

const createSelectTableData = (selectField, description) => {
  return { selectField, description };
};

const createProjectTableData = (textField, description) => {
  return { textField, description };
};

const selectTableRows = [
  createSelectTableData(
    "Bitbucket Workspace",
    "Bitbucket workspace ID where the new repository will be created."
  ),
  createSelectTableData(
    "Team",
    `Name of the team that the Bitbucket repository belongs to. 
    The select options for Team are populated based off of the value of the Bitbucket Workspace
    select field.
    This value determines which AWS account the solution will be deployed to.`
  ),
  createSelectTableData(
    "Bitbucket Project",
    `Name of the folder inside the Bitbucket workspace the repository will be created in. 
    The select options for Bitbucket Project are populated based off of the value of the Bitbucket Workspace
    select field.
    If the Bitbucket project does not exist yet, then the project will need to be created in the workspace manually before the repository can be created.`
  ),
  createSelectTableData(
    "Template Type",
    "Type of template needed inside the Bitbucket repository."
  ),
  createSelectTableData(
    "Template",
    `Name of the template needed inside the Bitbucket repository.
    The select options for Template are populated based on the value of the Template Type select field.`
  ),
  createSelectTableData(
    "Genesys Product",
    "Name of the Genesys product the Bitbucket repository is associated with."
  )
];

const projectTableRows = [
  createProjectTableData(
    "Project Number",
    `Number of the Statement of work (SOW) for the project.
    Binds to  Appveyor environment variable 'genesysProjectId'.
    This text field is only available for the genesyscsp Bitbucket workspace.
    This text field is optional and should only be used if the solution has a SOW.
    The Project Number value needs to correlate to an actual SOW number and not some arbitrary number.`
  ),
  createProjectTableData(
    "Customer Name",
    `Name of the customer the project solution is for.
    Binds to Appveyor environment variable 'customer'.
    This text field is only available for the genesyscsp Bitbucket workspace.
    This text input field is optional and should only be used if the solution is for a customer.`
  ),
  createProjectTableData(
    "Solution Name",
    "Name of the project solution. Binds to Appveyor environment variable 'solution'."
  ),
  createProjectTableData(
    "Solution Description",
    `Description of what the project solution does. This value will be added to the Bitbucket repository description.`
  )
];

const UserGuide = () => {
  const location = useLocation();
  const redirect = location.state === undefined ? true : false;
  const adminAccess =
    location.state === undefined ? null : location.state.adminAccess;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundImage: `url(${background})`
      }}
    >
      {/*If the user tried to go to /user-guide path directly redirect to main page */}
      {redirect && <Redirect to="/" />}
      <Box sx={{ marginBottom: "auto" }}>
        <Header adminAccess={adminAccess} />
      </Box>
      <Box
        sx={{
          bgcolor: "background.paper",
          boxShadow: 15,
          padding: "1%",
          border: "2px solid rgba(35,57,93)",
          borderRadius: 3,
          width: "75%",
          margin: "auto"
        }}
      >
        <Card elevation={0}>
          <CardContent>
            <Typography variant="h4" sx={{ fontWeight: 500 }}>
              Overview
            </Typography>
            <Typography>
              The Repository Creator application allows developers to create
              Bitbucket repositories that contains up to date development
              templates. To create a Bitbucket repository using the application,
              the user just needs to select and input values from the simple to
              use form. This application adds a layer of configuration on top of
              Bitbucket and also integrates with AppVeyor our build server. The
              values the user inputs into the repository creator form are passed
              from the application to the Appveyor environment variables for the
              Appveyor project associated with the Bitbucket repository. This
              ensures that when a deployment is triggered, the resources are
              created using the same naming convention and are tagged.
            </Typography>
          </CardContent>
          <CardContent>
            <Typography variant="h4" sx={{ fontWeight: 500 }}>
              Creating a Repository
            </Typography>
            <Typography>
              There is a form located under the CREATE REPOSITORY navigation tab
              which contains multiple select and text fields for the user to
              fill out.
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontWeight: 500, paddingTop: "20px" }}
            >
              Step 1: Select your settings
            </Typography>
            <Typography>
              This section of the form gathers information needed for the new
              Bitbucket repository. The table below is a description of each
              select field.
            </Typography>
            <TableContainer
              component={Paper}
              variant="outlined"
              sx={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{ fontWeight: 500 }}>
                        Select Field
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography sx={{ fontWeight: 500 }}>
                        Description
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectTableRows.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0
                        }
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.selectField}
                      </TableCell>
                      <TableCell align="left">{row.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography
              variant="h5"
              sx={{ fontWeight: 500, paddingTop: "20px" }}
            >
              Step 2: Enter project details
            </Typography>
            <Typography>
              This section of the form is used to create the name of the new
              Bitbucket repository. The text fields are populated based on the
              Bitbucket Workspace value selected. The table below is a
              description of each text field.
            </Typography>
            <TableContainer
              component={Paper}
              variant="outlined"
              sx={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{ fontWeight: 500 }}>
                        Text Field
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography sx={{ fontWeight: 500 }}>
                        Description
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectTableRows.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 }
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.textField}
                      </TableCell>
                      <TableCell align="left">{row.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography
              variant="h5"
              sx={{ fontWeight: 500, paddingTop: "20px" }}
            >
              Step 3: Create repository
            </Typography>
            <Typography>
              This section of the form allows the user to create the Bitbucket
              repository.{" "}
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, paddingTop: "20px" }}
            >
              Editing the Repository Name:
            </Typography>
            <Typography>
              The Repository Name text field is pre-populated based on the
              values entered from Step 2 which is the format that should be
              followed for naming a Bitbucket repository; however, there are
              instances where you might need to edit the repository name. An
              example of this would be adding "frontend" or "backend" to the end
              of the repository name since the Solution Name value entered from
              Step 2 should be the same across all Bitbucket repositories that
              belong to the same solution. If you start modifying the Repository
              Name text field and decide you want to reset it back to original
              pre-populated name click the icon next to the Reset Name label.{" "}
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, paddingTop: "20px" }}
            >
              Repository Name Preview:
            </Typography>
            <Typography>
              This section allows the user to view what the Bitbucket repository
              name will be before it is created. The Repository Name is
              populated in real-time when the user types into the text fields
              from Step 2 as well as when the user edits the Repository Name
              text field from Step 3.{" "}
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, paddingTop: "20px" }}
            >
              Create Repository Form Submission:
            </Typography>
            <Typography>
              Clicking the CREATE REPOSITORY button creates a Bitbucket
              repository that contains the cloned templates and an associated
              AppVeyor project. This process takes approximately twenty seconds
              and displays a success modal when the process is completed. The
              application will also open the location of the Bitbucket
              repository in a new browser tab if the browser’s pop-up blocker is
              disabled.
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, paddingTop: "20px" }}
            >
              Create Repository Form Submission Error:
            </Typography>
            <Typography>
              If there is an issue while creating the Bitbucket repository an
              error modal will display with the error message. Please send an
              email to PSAWSRequests@genesys.com with a screenshot of the error
              message and the DevOps team will assist.
            </Typography>
          </CardContent>
          {adminAccess && (
            <CardContent>
              <Typography variant="h4" sx={{ fontWeight: 500 }}>
                Setting Reviewers
              </Typography>
              <Typography>
                If the user is an admin of at least one Bitbucket workspace they
                should have a SET REVIEWERS tab in the navigation bar. This
                allows the admins to add or remove reviewers from the Bitbucket
                projects that are in the Bitbucket workspace the user is an
                admin of.
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: 500, paddingTop: "20px" }}
              >
                Viewing project reviewers
              </Typography>
              <Typography>
                The admin will need to fill out the select fields under the
                Select your settings section. Once the user selects a Bitbucket
                workspace and a Bitbucket Project, a View project reviewers
                section will populate and display a list of the project
                reviewers for the Bitbucket project.
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: 500, paddingTop: "20px" }}
              >
                Modifying project reviewers
              </Typography>
              <Typography>
                Clicking the MODIFY button will open a transfer list. The
                Members list contains the Bitbucket users that are members of
                the Bitbucket workspace. The Reviewers list contains the members
                that are reviewers of the Bitbucket project.
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: 500, paddingTop: "20px" }}
              >
                Adding project reviewers
              </Typography>
              <Typography>
                For the Members list, mark the checkbox for each user you want
                to be a reviewer for the selected Bitbucket project. Click the
                green arrow button that appears and the user will move to the
                Reviewers list.
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: 500, paddingTop: "20px" }}
              >
                Removing project reviewers
              </Typography>
              <Typography>
                For the Reviewers list, mark the checkbox for each user you do
                not want to be a reviewer for the selected Bitbucket project
                anymore. Click the red arrow button that appears and the user
                will move to the Members list.
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: 500, paddingTop: "20px" }}
              >
                Saving project reviewers modifications
              </Typography>
              <Typography>
                Click the green SAVE button once you are done adding or removing
                users from the Members or Reviewers list and you will be taken
                back to the View project reviewers section which will be updated
                with the changes made.
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: 500, paddingTop: "20px" }}
              >
                Cancelling project reviewers modifications
              </Typography>
              <Typography>
                If you wish to go back to the view project reviewers screen
                without saving click the red CANCEL button.
              </Typography>
            </CardContent>
          )}
          <CardContent>
            <Typography variant="h4" sx={{ fontWeight: 500 }}>
              Contact Us
            </Typography>
            <Typography>
              To request an improvement or report a bug in the Repository
              Creator application please email PSAWSRequests@genesys.com.
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box component="footer" sx={{ mt: "auto" }}>
        <Footer />
      </Box>
    </Box>
  );
};

export default UserGuide;
