import React from "react";
import { Button } from "@mui/material";

const CreateRepoButton = () => {
  return (
    <Button type="submit" variant="contained" color="secondary" size="small">
      Create Repository
    </Button>
  );
};

export default CreateRepoButton;
