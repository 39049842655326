import React from "react";
import ReviewersButton from "./ReviewersButton";
import {
  Grid,
  Typography,
  Box,
  Card,
  List,
  ListItemText,
  Stack
} from "@mui/material";

const ViewProjectReviewers = (props) => {
  const { projectReviewers, handleButtonClick } = props;

  return (
    <Box>
      <Grid container spacing={{ xs: 2, sm: 2, md: 5, lg: 5 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h5" sx={{ fontWeight: 500 }}>
              View project reviewers
            </Typography>
            <ReviewersButton
              text="modify"
              handleButtonClick={handleButtonClick}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card
            variant="outlined"
            sx={{
              backgroundColor: "white",
              minHeight: "120px"
            }}
          >
            {projectReviewers && projectReviewers.length === 0 && (
              <Box>
                <Typography sx={{ paddingTop: "0px", paddingLeft: "8px" }}>
                  There are no project reviewers set
                </Typography>
              </Box>
            )}
            {projectReviewers && projectReviewers.length > 0 && (
              <List
                sx={{
                  padding: 0,
                  maxHeight: 230,
                  overflow: "auto"
                }}
              >
                {projectReviewers.map((item, index) => (
                  <ListItemText
                    key={index}
                    sx={{ paddingTop: "0px", paddingLeft: "8px" }}
                  >
                    {item.name}
                  </ListItemText>
                ))}
              </List>
            )}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ViewProjectReviewers;
