import React, { Fragment } from "react";
import { Typography, FormControlLabel, Checkbox } from "@mui/material";

const OptionalCheckbox = (props) => {
	const {
		labelText, 
		status,
		handleChange,
	} = props;

  return (
    <Fragment>
			<FormControlLabel
				control={
					<Checkbox
						size="small"
						id={labelText}
						checked={status}
						onChange={handleChange}
					/>
				}
				label={<Typography variant="caption">Optional</Typography>}
				labelPlacement="end"
			/>
    </Fragment>
  );
};

export default OptionalCheckbox;
