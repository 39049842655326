import React from "react";
import {
  Tooltip,
  IconButton,
  FormControlLabel,
  Typography
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import tooltipTextConfig from "../../services/tooltipText";
import utils from "../../services/utils";

const FormLabel = ({ labelText }) => {
  // Get the correct tooltip text based on the label name
  const text = utils.camelCase(labelText);
  const tooltipText = tooltipTextConfig[text];

  return (
    <FormControlLabel
      control={
        <Tooltip title={tooltipText} arrow>
          <IconButton color="secondary" size="small">
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      }
      label={<Typography sx={{ fontWeight: 500 }}>{labelText}</Typography>}
      labelPlacement="start"
      sx={{ margin: 0 }}
    />
  );
};

export default FormLabel;
