import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect } from "react";
import backendAPI from "../../services/api";
import { Box, Typography } from "@mui/material";
import moment from "moment";

/* The Footer component displays the release date which is the date the repo creator was deployed on the container */
const Footer = () => {
  const { oktaAuth } = useOktaAuth();
  const [releaseDate, setReleaseDate] = useState("");

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      try {
        const accessToken = oktaAuth.getAccessToken();
        const metadata = await backendAPI.getMetadata(accessToken);
        const pulledDate = metadata.PullStartedAt;
        if (isMounted) {
          setReleaseDate(moment(pulledDate).format("MMM Do YYYY"));
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
    return () => {
      isMounted = false;
    };
  }, [oktaAuth]);

  return (
    <Box
      sx={{
        position: "static",
        bgcolor: "primary.main",
        height: "64px",
        boxShadow:
          "0px -2px 4px -1px rgb(0 0 0 /20%), 0px -4px 5px 0px rgb( 0 0 0 /14%), 0px -1px 10px 0px rgb(0 0 0 /12%)",
        alignItems: "center",
        boxSizing: "border-box"
      }}
    >
      <Typography
        component="div"
        variant="caption"
        align="right"
        sx={{ paddingRight: "24px", color: "white", paddingTop: "25px" }}
      >
        Release date: {releaseDate}
      </Typography>
    </Box>
  );
};
export default Footer;
