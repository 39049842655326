import React, { Fragment } from "react";
import FormLabel from "../../formLabel/FormLabel";
import { Grid, TextField } from "@mui/material";

const SolutionDescription = ({
  handleSolutionDescriptionChange,
  solutionDescription
}) => {
  return (
    <Fragment>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <FormLabel labelText="Solution Description" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={8}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <TextField
          id="outlined-basic"
          label="Solution Description"
          variant="outlined"
          size="small"
          onChange={handleSolutionDescriptionChange}
          value={solutionDescription}
          required
          sx={{ backgroundColor: "white" }}
          fullWidth
          multiline
          maxRows={4}
        />
      </Grid>
    </Fragment>
  );
};

export default SolutionDescription;
