import React, { Fragment } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import config from "../../config";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CorsErrorModal = ({ corsErrorModalOpen, setCorsErrorModalOpen }) => {
  const { issuer } = config.okta;
  const baseUrl = issuer.split("/oauth2")[0];
  const hostParts = new URL(baseUrl).host.split(".");
  hostParts[0] += "-admin";
  const adminHost = hostParts.join(".");
  const corsAdminUrl = `https://${adminHost}/admin/access/api/trusted_origins`;

  // CORS error modal
  return (
    <Fragment>
      <Modal
        open={corsErrorModalOpen}
        onClose={() => setCorsErrorModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Cors Error
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You may need to add your origin {window.location.origin} to list of
            trusted origins in your{" "}
            <a href={corsAdminUrl} target="_blank" rel="noopener noreferrer">
              Okta Administrator Dashboard
            </a>
          </Typography>
        </Box>
      </Modal>
    </Fragment>
  );
};
export default CorsErrorModal;
