const tooltipTextConfig = {
  bitbucketWorkspace: `Bitbucket workspace ID where the new repository will be created`,
  team: `Name of the team that the Bitbucket repository belongs to`,
  bitbucketProject: `Name of the folder inside the Bitbucket workspace the repository will be created in`,
  templateType: `Type of template needed inside the Bitbucket repository`,
  template: `Name of the template needed inside the Bitbucket repository`,
  genesysProduct: `Name of the Genesys product the Bitbucket repository is associated with`,
  projectNumber: `Number of the Statement of work (SOW) for the project`,
  customerName: `Name of the customer the project solution is for`,
  solutionName: `Name of the project solution`,
  solutionDescription: `Description of what the project solution does`,
  repositoryName: `Edit this field if you need to add to the generated repository name (example: Frontend)`
};

export default tooltipTextConfig;
