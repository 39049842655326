export const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#23395d"
    },
    secondary: {
      main: "#ff4f1f"
    },
    warning: {
      main: "#ff8f14"
    },
    error: {
      main: "#d32f2f",
      dark: "#ad2b28"
    },
    success: {
      main: "#2e7d32",
      dark: "#29672b"
    },
    background: {
      default: "#e3e3e3",
      paper: "#f3f3f3"
    },
    info: {
      main: "#3b90aa"
    }
  },
  shape: {
    borderRadius: 3
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    },
    MuiList: {
      dense: true
    },
    MuiMenuItem: {
      dense: true
    },
    MuiTable: {
      size: "small"
    },
    MuiTooltip: {
      arrow: true
    }
  }
};
