import React from "react";
import { BeatLoader } from "react-spinners";
import { Dialog, DialogTitle, DialogContent, Box } from "@mui/material";

const WaitingModal = (props) => {
  const { isLoading, header = "Loading" } = props;

  return (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={isLoading}
      sx={{
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center"
      }}
    >
      <DialogTitle>{header}</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ color: "secondary.main" }}>
          <BeatLoader color="currentColor" />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WaitingModal;
