import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  Grid,
  MenuItem,
  Typography
} from "@mui/material";
import FormLabel from "../formLabel/FormLabel";

const FormSelect = (props) => {
  const {
    handleWorkspaceChange,
    workSpaceList,
    workspace,
    handleTeamNameChange,
    teamList,
    teamName,
    handleProjectChange,
    projectList,
    project,
    handleTemplateTypeChange,
    templateTypeList,
    templateType,
    handleTemplateChange,
    templateList,
    template,
    handleProductNameChange,
    productName
  } = props;

  return (
    <Grid container columnSpacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={12} pt={0}>
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          Step 1: Select your settings
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <FormLabel labelText="Bitbucket Workspace" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={8}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <FormControl fullWidth size="small" required>
          <InputLabel id="demo-simple-select-label">
            Bitbucket Workspace
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={workspace}
            label="Bitbucket Workspace"
            onChange={handleWorkspaceChange}
            sx={{ backgroundColor: "white" }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
          >
            {workSpaceList.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <FormLabel labelText="Team" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={8}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <FormControl fullWidth size="small" required>
          <InputLabel id="demo-simple-select-label">Team</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={teamName}
            label="Team"
            onChange={handleTeamNameChange}
            sx={{ backgroundColor: "white" }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
          >
            {teamList.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <FormLabel labelText="Bitbucket Project" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={8}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <FormControl fullWidth size="small" required>
          <InputLabel id="demo-simple-select-label">
            Bitbucket Project
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={project}
            label="Bitbucket Project"
            onChange={handleProjectChange}
            sx={{ backgroundColor: "white" }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
          >
            {projectList.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <FormLabel labelText="Template Type" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={8}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <FormControl fullWidth size="small" required>
          <InputLabel id="demo-simple-select-label">Template Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={templateType}
            label="Template Type"
            onChange={handleTemplateTypeChange}
            sx={{ backgroundColor: "white" }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
          >
            {templateTypeList.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <FormLabel labelText="Template" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={8}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <FormControl fullWidth size="small" required>
          <InputLabel id="demo-simple-select-label">Template</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={template}
            label="Template"
            onChange={handleTemplateChange}
            sx={{ backgroundColor: "white" }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
          >
            {templateList.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <FormLabel labelText="Genesys Product" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={8}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <FormControl fullWidth size="small" required>
          <InputLabel id="demo-simple-select-label">Genesys Product</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={productName}
            label="Genesys Product"
            onChange={handleProductNameChange}
            sx={{ backgroundColor: "white" }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
          >
            <MenuItem value="Genesys Cloud">Genesys Cloud</MenuItem>
            <MenuItem value="PureConnect">PureConnect</MenuItem>
            <MenuItem value="Genesys MultiCloud CX">
              Genesys MultiCloud CX
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default FormSelect;
