import React from "react";
import SolutionName from "./SolutionName";
import SolutionDescription from "./SolutionDescription";
import FormLabel from "../../formLabel/FormLabel";
import OptionalCheckbox from "./OptionalCheckbox";
import utils from "../../../services/utils";
import {
  Grid,
  TextField,
  Typography,
} from "@mui/material";

/* The FormInput component includes the project details text fields. 
   The project details text fields change depending on the workspace selected. */
const FormInput = (props) => {
  const {
    workspace,
    handleSolutionNameChange,
    solutionName,
    handleProjectNumberChange,
    projectNumber,
    setProjectNumber,
    handleCustomerNameChange,
    customerName,
    setCustomerName,
    solutionDescription,
    handleSolutionDescriptionChange,
    setRepositoryName,
    teamName,
    customerNameChecked,
    setCustomerNameChecked,
    projectNumberChecked,
    setProjectNumberChecked
  } = props;

  // handles when the user selects or deselects the Optional checkbox
  const handleChange = (event) => {
    const checkedItem = event.target.id.toLowerCase();
    if (checkedItem === "customer name") {
      setCustomerNameChecked(event.target.checked);
      setCustomerName("");
      setRepositoryName(
        utils.generateName(
          utils.formatString(projectNumber),
          "",
          utils.formatString(solutionName)
        )
      );
    } else if (checkedItem === "project number") {
      setProjectNumberChecked(event.target.checked);
      setProjectNumber("");
      setRepositoryName(
        utils.generateName(
          "",
          utils.formatString(customerName),
          utils.formatString(solutionName)
        )
      );
    }
  };

  // change project details text fields based on workspace selected
  switch (workspace.toLowerCase()) {
    case "genesyscsp":
      return (
        <Grid container columnSpacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} pt={0}>
            <Typography variant="h5" sx={{ fontWeight: 500 }}>
              Step 2: Enter project details
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
          >
            <FormLabel labelText="Project Number" />
            {(teamName.toLowerCase() === "innovations") && <OptionalCheckbox labelText="Project Number" status={projectNumberChecked} handleChange={handleChange} />}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
          >
            <TextField
              id="outlined-basic"
              label="Project Number"
              variant="outlined"
              size="small"
              onChange={handleProjectNumberChange}
              value={utils.formatTextInputValue(projectNumber)}
              required={((teamName.toLowerCase() === "custom solutions practice") || !projectNumberChecked) ? true : false}
              disabled={((teamName.toLowerCase() === "innovations") && projectNumberChecked) ? true : false}
              sx={((teamName.toLowerCase() === "innovations") &&  projectNumberChecked) ? { bgcolor: "darkGray" } : { bgcolor: "white" }}
              fullWidth 
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
          >
            <FormLabel labelText="Customer Name" />
            {(teamName.toLowerCase() === "innovations") && <OptionalCheckbox labelText="Customer Name" status={customerNameChecked} handleChange={handleChange} />}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
          >
            <TextField
              id="outlined-basic"
              label="Customer Name"
              variant="outlined"
              size="small"
              onChange={handleCustomerNameChange}
              value={utils.formatTextInputValue(customerName)}
              required={((teamName.toLowerCase() === "custom solutions practice") || !customerNameChecked) ? true : false}
              disabled={((teamName.toLowerCase() === "innovations") && customerNameChecked) ? true : false}
              sx={((teamName.toLowerCase() === "innovations") &&  customerNameChecked) ? { bgcolor: "darkGray" } : { bgcolor: "white" }}
              fullWidth
            />
          </Grid>
          <SolutionName
            handleSolutionNameChange={handleSolutionNameChange}
            solutionName={solutionName}
          />
          <SolutionDescription
            handleSolutionDescriptionChange={handleSolutionDescriptionChange}
            solutionDescription={solutionDescription}
          />
        </Grid>
      );
    default:
      return (
        <Grid container columnSpacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} pt={0}>
            <Typography variant="h5" sx={{ fontWeight: 500 }}>
              Step 2: Enter project details
            </Typography>
          </Grid>
          <SolutionName
            handleSolutionNameChange={handleSolutionNameChange}
            solutionName={solutionName}
          />
          <SolutionDescription
            handleSolutionDescriptionChange={handleSolutionDescriptionChange}
            solutionDescription={solutionDescription}
          />
        </Grid>
      );
  }
};

export default FormInput;
