import React, { Fragment } from "react";
import FormLabel from "../../formLabel/FormLabel";
import { Grid, TextField } from "@mui/material";
import utils from "../../../services/utils";

const SolutionName = ({ handleSolutionNameChange, solutionName }) => {
  return (
    <Fragment>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <FormLabel labelText="Solution Name" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={8}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <TextField
          id="outlined-basic"
          label="Solution Name"
          variant="outlined"
          size="small"
          onChange={handleSolutionNameChange}
          value={utils.formatTextInputValue(solutionName)}
          required
          sx={{ backgroundColor: "white" }}
          fullWidth
        />
      </Grid>
    </Fragment>
  );
};

export default SolutionName;
