import React from "react";
import FormLabel from "../formLabel/FormLabel";
import {
  FormControl,
  Box,
  InputLabel,
  Select,
  Grid,
  MenuItem,
  Typography
} from "@mui/material";

const SelectProjectSettings = (props) => {
  const {
    handleWorkspaceChange,
    workSpaceList,
    workspace,
    handleProjectChange,
    projectList,
    projectName
  } = props;

  return (
    <Box sx={{ paddingRight: "40px" }}>
      <Grid container spacing={{ xs: 2, sm: 2, md: 5, lg: 5 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h5" sx={{ fontWeight: 500 }}>
            Select your settings
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <FormLabel labelText="Bitbucket Workspace" />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-simple-select-label">
              Bitbucket Workspace
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={workspace}
              label="Bitbucket Workspace"
              onChange={handleWorkspaceChange}
              sx={{ backgroundColor: "white" }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
            >
              {workSpaceList.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <FormLabel labelText="Bitbucket Project" />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <FormControl fullWidth size="small" required>
            <InputLabel id="demo-simple-select-label">
              Bitbucket Project
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={projectName}
              label="Bitbucket Project"
              onChange={handleProjectChange}
              sx={{ backgroundColor: "white" }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
            >
              {projectList.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SelectProjectSettings;
