import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  Grid,
  Typography
} from "@mui/material";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Close from "@mui/icons-material/Close";

const ConfirmationModal = (props) => {
  const { isOpen, toggle, header, body } = props;

  return (
    <Dialog fullWidth maxWidth={"xs"} open={isOpen} onClose={toggle}>
      <DialogTitle color="white" sx={{ bgcolor: "success.main", padding: 0 }}>
        <Grid container>
          <Grid item xs={3} />
          <Grid item xs={6} sx={{ textAlign: "center" }}>
            <Box
              sx={{
                display: "inline-block",
                paddingTop: "10px",
                paddingBottom: "10px"
              }}
            >
              <CheckCircle
                fontSize="small"
                sx={{ display: "inline-block", verticalAlign: "middle" }}
              />{" "}
              <Typography
                sx={{
                  display: "inline-block",
                  fontWeight: 500,
                  verticalAlign: "center"
                }}
              >
                {header.toUpperCase()}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={toggle}
              color="inherit"
              sx={{
                float: "right",
                minWidth: 0,
                width: "40px",
                height: "30px",
                ":hover": {
                  bgcolor: "success.dark"
                }
              }}
            >
              {" "}
              <Close fontSize="small" />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{body}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationModal;
