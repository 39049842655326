import React from "react";
import {
  Paper,
  Box,
  Typography,
  Grid,
  TextField,
  Stack,
  FormGroup,
  FormControlLabel,
  IconButton
} from "@mui/material";
import CreateRepoButton from "./CreateRepoButton";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import FormLabel from "../formLabel/FormLabel";

const FormRepoNamePreview = (props) => {
  const { repositoryName, handleRepositoryNameChange, resetRepositoryName } =
    props;

  return (
    <Grid container columnSpacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={12} pt={0}>
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          Step 3: Create repository
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <FormGroup row={true}>
          <FormLabel labelText="Repository Name" />
          <FormControlLabel
            control={
              <IconButton
                color="secondary"
                size="small"
                onClick={resetRepositoryName}
              >
                <RestartAltIcon />
              </IconButton>
            }
            label={<Typography variant="caption">Reset Name</Typography>}
            labelPlacement="end"
          />
        </FormGroup>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={8}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <TextField
          id="outlined-basic"
          label="Repository Name"
          variant="outlined"
          size="small"
          onChange={handleRepositoryNameChange}
          value={repositoryName}
          required
          sx={{ backgroundColor: "white" }}
          fullWidth
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        pt={{ xs: 2, sm: 2, md: 5, lg: 5 }}
      >
        <Paper
          variant="outlined"
          sx={{
            paddingBottom: "40px",
            textAlign: "center",
            minHeight: "120px",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            bgcolor: "white"
          }}
        >
          {" "}
          <Stack>
            <Typography variant="h6" sx={{ paddingTop: 1 }}>
              Repository Name Preview
            </Typography>
            <Typography
              sx={{
                paddingTop: 3,
                display: "inline-block",
                fontWeight: 500,
                wordBreak: "break-word"
              }}
            >
              {repositoryName}
            </Typography>
          </Stack>
          <Box sx={{ position: "absolute", bottom: 10 }}>
            <CreateRepoButton />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default FormRepoNamePreview;
