import React, { useState, useCallback } from "react";
import { Box } from "@mui/material";
import Header from "../header/Header";
import RepoCreatorForm from "../repoCreator/RepoCreatorForm";
import Footer from "../footer/Footer";
import background from "../../img/gplaypattern.png";

const Home = () => {
  const [adminAccess, setAdminAccess] = useState(false);

  const getUserAdminAccess = useCallback(async (userAdminAccess) => {
    setAdminAccess(userAdminAccess);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundImage: `url(${background})`
      }}
    >
      <Box sx={{ marginBottom: "auto" }}>
        <Header adminAccess={adminAccess} />
      </Box>
      <RepoCreatorForm getUserAdminAccess={getUserAdminAccess} />
      <Box component="footer" sx={{ mt: "auto" }}>
        <Footer />
      </Box>
    </Box>
  );
};

export default Home;
