import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect, Fragment } from "react";
import backendAPI from "../../services/api";
import utils from "../../services/utils";
import WaitingModal from "../modals/WaitingModal";
import ConfirmationModal from "../modals/ConfirmationModal";
import AlertModal from "../modals/AlertModal";
import FormSelect from "./FormSelect";
import FormInput from "./formInput/FormInput";
import FormRepoNamePreview from "./FormRepoNamePreview";
import { Box, Grid } from "@mui/material";

const RepoCreatorForm = ({ getUserAdminAccess }) => {
  // okta user authentication
  const { oktaAuth } = useOktaAuth();

  // repo creator form select menu values
  const [config, setConfig] = useState([]);
  const [workSpaceList, setWorkSpaceList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [templateTypeList, setTemplateTypeList] = useState([]);
  const [templateList, setTemplateList] = useState([]);

  // repo creator form values the user selected
  const [workspace, setWorkspace] = useState("");
  const [teamName, setTeamName] = useState("");
  const [project, setProject] = useState("");
  const [templateType, setTemplateType] = useState("");
  const [template, setTemplate] = useState("");
  const [productName, setProductName] = useState("");

  // repo creator form values the user entered
  const [solutionName, setSolutionName] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [projectNumber, setProjectNumber] = useState("");
  const [solutionDescription, setSolutionDescription] = useState("");
  const [repositoryName, setRepositoryName] = useState("");

  // Bitbucket Ids
  const [templateId, setTemplateId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [teamId, setTeamId] = useState("");

  // Optional Checkbox state
  const [customerNameChecked, setCustomerNameChecked] = useState(false);
  const [projectNumberChecked, setProjectNumberChecked] = useState(false);

  // utils
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      try {
        const accessToken = oktaAuth.getAccessToken();
        const userInfo = await oktaAuth.getUser();
        const userName = userInfo.name;
        setIsLoading(true);
        const data = await backendAPI.getConfig(accessToken);
        if (isMounted) {
          await loadForm(data, userName);
          const userAdminAccess = utils.getAdminAccess(data, userName);
          getUserAdminAccess(userAdminAccess);
          setIsLoading(false);
        }
      } catch (error) {
        setError(error.message);
      }
    };
    getData();
    return () => {
      isMounted = false;
    };
  }, [oktaAuth, getUserAdminAccess]);

  // Set repo creator initial form state for select menu values
  const loadForm = async (data, userName) => {
    setConfig(data);
    const workspaceList = utils.getWorkspaces(data, userName);
    setWorkSpaceList(workspaceList);
    setTemplateTypeList(utils.getTemplateTypes(data));
  };

  // Repo creator form handlers for changing the select settings
  const handleWorkspaceChange = (event) => {
    setWorkspace(event.target.value);
    const workspaceValue = event.target.value;
    // If user changes the workspace when project or team is selected, reset state
    if (!!project || !!teamName) {
      setProject("");
      setTeamName("");
    }
    // get project list based off of workspace selected
    const projectList = utils.getProjectNames(config, workspaceValue);
    setProjectList(projectList);
    // get team list based off of workspace selected
    const teamList = utils.getTeams(config, workspaceValue);
    setTeamList(teamList);
    // If there are no projects associated with a Bitbucket workspace display error modal
    if (projectList.length === 0) {
      setError(
        `There are no projects in the ${workspaceValue} Bitbucket workspace; Please create projects in your Bitbucket workspace`
      );
    }
    // If user changes workspace reset state
    setCustomerName("");
    setProjectNumber("");
    setRepositoryName(
      utils.generateName("", "", utils.formatString(solutionName))
    );
  };

  const handleTeamNameChange = (event) => {
    setTeamName(event.target.value);
    setTeamId(utils.getTeamId(config, workspace, event.target.value));
    // If user changes team reset state
    setProjectNumber("");
    setCustomerName("");
    setProjectNumberChecked(false);
    setCustomerNameChecked(false);
    setRepositoryName(
      utils.generateName("", "", utils.formatString(solutionName))
    );
  };

  const handleProjectChange = (event) => {
    setProject(event.target.value);
    setProjectId(utils.getProjectId(config, workspace, event.target.value));
  };

  const handleTemplateTypeChange = (event) => {
    setTemplateType(event.target.value);
    const templateTypeValue = event.target.value;
    // If user changes the template type when template is selected, reset state
    if (!!template) {
      setTemplate("");
    }
    setTemplateList(utils.getTemplates(config, templateTypeValue));
  };

  const handleTemplateChange = (event) => {
    setTemplate(event.target.value);
    setTemplateId(utils.getTemplateId(config, event.target.value));
  };

  const handleProjectNumberChange = (event) => {
    const projectNumber = event.target.value;
    setProjectNumber(projectNumber);
    setRepositoryName(
      utils.generateName(
        utils.formatString(projectNumber),
        utils.formatString(customerName),
        utils.formatString(solutionName)
      )
    );
  };

  const handleCustomerNameChange = (event) => {
    const customerName = event.target.value;
    setCustomerName(customerName);
    setRepositoryName(
      utils.generateName(
        utils.formatString(projectNumber),
        utils.formatString(customerName),
        utils.formatString(solutionName)
      )
    );
  };

  const handleSolutionNameChange = (event) => {
    const solutionName = event.target.value;
    setSolutionName(solutionName);
    setRepositoryName(
      utils.generateName(
        utils.formatString(projectNumber),
        utils.formatString(customerName),
        utils.formatString(solutionName)
      )
    );
  };

  // clears the repo creator form and resets the state
  const clearFormState = () => {
    setWorkspace("");
    setTeamName("");
    setProject("");
    setTemplateType("");
    setTemplate("");
    setProductName("");
    setProjectNumber("");
    setCustomerName("");
    setSolutionName("");
    setSolutionDescription("");
    setRepositoryName("");
    setTeamList([]);
    setProjectList([]);
    setTemplateList([]);
    setCustomerNameChecked(false);
    setProjectNumberChecked(false);
  };

  // handler for when the Create Repository button is clicked
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setText("Creating Repository");
    setIsLoading(true);

    const slug = utils.slugify(repositoryName);

    const requestBody = {
      teamId: teamId,
      bitbucketProjectId: projectId,
      solution: utils.formatString(solutionName),
      platform: productName,
      workspace: workspace.toLowerCase(),
      templateId: templateId,
      genesysProjectId: utils.formatString(projectNumber),
      customer: utils.formatString(customerName),
      description: solutionDescription.trim(),
      repositoryName: repositoryName,
      slug: slug
    };

    // Call backend API to create a Bitbucket repository
    try {
      const accessToken = oktaAuth.getAccessToken();
      await backendAPI.createRepository(requestBody, accessToken);
      setSuccessMessage("Your Bitbucket repository has been created");
      window.open(`https://bitbucket.org/${workspace}/${slug}`);
      await clearFormState();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <WaitingModal isLoading={isLoading} header={!text ? "Loading" : text} />
      <ConfirmationModal
        isOpen={!!successMessage}
        toggle={() => {
          setSuccessMessage(undefined);
        }}
        header="Success"
        body={successMessage}
      />
      <AlertModal
        isOpen={!!error}
        toggle={() => {
          setError(undefined);
        }}
        header="Error"
        body={error}
      />
      <Box
        sx={{
          bgcolor: "background.paper",
          boxShadow: 15,
          padding: "1%",
          border: "2px solid rgba(35,57,93)",
          borderRadius: 3,
          width: "75%",
          margin: "auto"
        }}
      >
        <form onSubmit={handleFormSubmit}>
          <Grid container direction="row" columnSpacing={1}>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <FormSelect
                handleWorkspaceChange={handleWorkspaceChange}
                workSpaceList={workSpaceList}
                workspace={workspace}
                handleTeamNameChange={handleTeamNameChange}
                teamList={teamList}
                teamName={teamName}
                handleProjectChange={handleProjectChange}
                projectList={projectList}
                project={project}
                handleTemplateTypeChange={handleTemplateTypeChange}
                templateTypeList={templateTypeList}
                templateType={templateType}
                handleTemplateChange={handleTemplateChange}
                templateList={templateList}
                template={template}
                handleProductNameChange={(e) => {
                  setProductName(e.target.value);
                }}
                productName={productName}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <FormInput
                workspace={workspace}
                handleSolutionNameChange={handleSolutionNameChange}
                solutionName={solutionName}
                handleProjectNumberChange={handleProjectNumberChange}
                projectNumber={projectNumber}
                setProjectNumber={setProjectNumber}
                handleCustomerNameChange={handleCustomerNameChange}
                customerName={customerName}
                setCustomerName={setCustomerName}
                solutionDescription={solutionDescription}
                handleSolutionDescriptionChange={(e) => {
                  setSolutionDescription(e.target.value);
                }}
                setRepositoryName={setRepositoryName}
                teamName={teamName}
                customerNameChecked={customerNameChecked} 
                setCustomerNameChecked={setCustomerNameChecked}
                projectNumberChecked={projectNumberChecked} 
                setProjectNumberChecked={setProjectNumberChecked}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <FormRepoNamePreview
                repositoryName={repositoryName}
                handleRepositoryNameChange={(e) => {
                  setRepositoryName(e.target.value);
                }}
                resetRepositoryName={() => {
                  setRepositoryName(
                    utils.generateName(
                      utils.formatString(projectNumber),
                      utils.formatString(customerName),
                      utils.formatString(solutionName)
                    )
                  );
                }}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </Fragment>
  );
};

export default RepoCreatorForm;
