import React from "react";
import { Button } from "@mui/material";

const ReviewersButton = ({ text, handleButtonClick, color = "secondary" }) => {
  return (
    <Button
      size="small"
      variant="contained"
      color={color}
      onClick={handleButtonClick}
      sx={{ marginRight: "10px" }}
    >
      {text}
    </Button>
  );
};

export default ReviewersButton;
